.title {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  text-align: center;
  font-family: "Bebas Neue";
  border: 2px solid purple;
  width: max-content;
  padding: 8px 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  transition: 0.3s ease-in-out;
  position: relative;
  z-index: 999;
}

.title span {
  font-size: 14px;
  color: purple;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;

  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (hover: hover) {
  .title:hover {
    padding: 12px 20px 20px 20px;
    border: 4px solid turquoise;
    border-radius: 8px;
  }

  .title:hover span {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    top: 80%;
  }
}
.title:active {
  padding: 12px 20px 20px 20px;
  border: 4px solid turquoise;
  border-radius: 8px;
}

.title:active span {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  top: 80%;
}
