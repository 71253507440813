body {
  background-color: white;
}

.quoteContainer {
  font-family: "Alkatra";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
h2 {
  font-family: "Bebas Neue";
  font-size: 32px;
  margin: 0 10px;
  text-align: center;
}
span {
  color: turquoise;
}
p {
  font-weight: bold;
  font-size: 18px;
}
button {
  cursor: pointer;
  font-family: "Bebas Neue";
  font-size: 20px;
  background-color: turquoise;
  color: black;
  border-radius: 4px;
  border: 1px solid black;
  height: 50px;
  min-width: 200px;
  letter-spacing: 1px;
}

@media (hover: hover) {
  button:hover {
    background-color: purple;
    color: white;
  }
}

button:active {
  background-color: purple;
  color: white;
}
